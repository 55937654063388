// https://docs.launchdarkly.com/sdk/client-side/react
import * as LD from 'launchdarkly-js-client-sdk';
import { useFlags as _useFlags } from 'launchdarkly-react-client-sdk';

import type { Profile } from './auth';
import type { LDFlags } from './types';

export let ldClient: LD.LDClient | null = null;

export type LDContext = LD.LDContext;

export function createLDContext(profile?: Profile | null): LDContext {
  const { firebaseUid, email, stats } = profile ?? {};
  const ldContext = firebaseUid
    ? {
        kind: 'user',
        key: firebaseUid,
        email,
        ...stats,
      }
    : {
        kind: 'user',
        anonymous: true,
        key: 'PLAYER_FRONTEND_ANONYMOUS',
      };
  return ldContext;
}

export function initializeLaunchDarkly(ldContext: LDContext): Promise<LD.LDClient> {
  const client = LD.initialize(import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID, {
    ...ldContext,
    last_identify: new Date().toISOString(),
  });

  return new Promise((resolve, reject) => {
    // https://docs.launchdarkly.com/sdk/client-side/react/react-web#initializing-using-asyncwithldprovider
    client.on('ready', () => {
      ldClient = client;
      resolve(client);
    });

    client.on('error', (err) => {
      reject(err);
    });
  });
}

/**
 * Returns the static value of a flag
 * @param flag
 * @param defaultValue
 */
export function resolveFlag<T = boolean>(flag: LDFlags, defaultValue: T): T {
  if (ldClient) {
    return ldClient.variation(flag, defaultValue);
  } else if (window.parent?.window?.flags) {
    return window.parent.window.flags[flag];
  }
  console.warn('LaunchDarkly client is missing');
  return defaultValue;
}

/**
 * Returns the observable value of a flag
 * @param flag
 * @param defaultValue
 */
export function useFlag<T = boolean>(flag: LDFlags, defaultValue: T): T {
  const ldFlags = _useFlags<Record<LDFlags, any>>();
  const flags = window.parent?.window?.flags ?? ldFlags;
  return resolveFlag(flag, flags[flag] ?? defaultValue);
}

/**
 * Wrapper for launch darkly useFlags hook, intended to favor window flags if exist.
 */
export function useFlags(): Record<LDFlags, any> {
  const ldFlags = _useFlags<Record<LDFlags, any>>();
  const flags = window.parent?.window?.flags ?? ldFlags;
  return flags;
}

/**
 * Returns launchDarkly client
 */
export function getLDClient() {
  if (!ldClient) {
    throw new Error('LaunchDarkly client is missing');
  }
  return ldClient;
}
