import { StrictMode, type ReactNode } from 'react';

import ReactDOM from 'react-dom/client';

import '../../index.scss';

import Landing from './Landing/Landing';

export function LandingPage() {
  return (
    <>
      <Landing />
    </>
  );
}

function render(component: ReactNode) {
  const el = document.getElementById('root');

  if (!el) {
    throw new Error('Root element not found');
  }
  const root = ReactDOM.createRoot(el);

  root.render(<StrictMode>{component}</StrictMode>);
}

render(<LandingPage />);
