import BackgroundPattern from '../../../../components/backrounds/BackgroundPattern';
import BlackButton from '../../../../components/buttons/BlackButton';
import { LandingHeader } from '../headers/LandingHeader';
import type { HeaderProps } from '../types';

export function CasinoBanner({ openSignUp }: HeaderProps) {
  return (
    <div>
      <BackgroundPattern color="yellow">
        <div className="home__hero">
          <LandingHeader openSignUp={openSignUp} />
          <div className={'home__hero-content'}>
            <div className="home__hero-content-title">
              #1 SOCIAL <div>CASINO</div>
            </div>
            <div className="home__hero-content-subtitle">IN AMERICA</div>
            <div className="home__hero-content-text">GET READY TO WIN BIG!</div>
            <div className="home__hero-btn">
              <BlackButton onClick={() => openSignUp?.()}>JOIN NOW</BlackButton>
            </div>
          </div>
        </div>
      </BackgroundPattern>
    </div>
  );
}
