/* eslint-disable react/prop-types */

import Button from '../../../../components/buttons/Button';
import { PreloadBlur } from '../../../../components/PreloadBlur/PreloadBlur';
import { getThumbnailUrl } from '../../../../utils/getThumbnailUrl';
import { categories } from '../../data/categories';

type Props = {
  openSignUp?(): void;
};
export const GamesBanner: React.FC<Props> = ({ openSignUp }) => {
  const firstCategory = categories[0];
  const games = firstCategory?.subCategoryGames.slice(0, 4) || [];

  return (
    <div className="home__content-play">
      <div className="home__content-play-title">
        <img alt="" src={firstCategory?.imageUrl} style={{ width: 28, margin: 2 }} />
        <p>
          TOP <span>Free-to-play </span>Casino STYLE GAMES
        </p>
        <img alt="" src={firstCategory?.imageUrl} style={{ width: 28, margin: 2 }} />
      </div>
      <div className="home__content-play-imgs">
        {games.map((data: any) =>
          data ? (
            <PreloadBlur
              key={data.masterCasinoGameId}
              render={(props) => (
                <picture {...props}>
                  <source
                    media="screen and (min-width: 1100px) and (min-height: 700px)"
                    srcSet={getThumbnailUrl(data, 'Long')}
                  />
                  <img alt={data.name} loading="lazy" src={getThumbnailUrl(data, 'Long')} />
                </picture>
              )}
            />
          ) : null,
        )}
      </div>
      <Button btnType="main" handleOnClick={() => openSignUp?.()}>
        SIGN UP TO PLAY FOR FREE
      </Button>
    </div>
  );
};
