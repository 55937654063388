import { useCallback, type FC, useState, type ReactNode } from 'react';

import classNames from 'classnames';

import styles from './PreloadBlur.module.scss';

type PreloadBlurProps = {
  render: (props: { onLoad: VoidFunction }) => ReactNode;
  className?: string;
};

export const PreloadBlur: FC<PreloadBlurProps> = ({ render, className }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <div
      className={classNames(
        className,
        styles.preloadBlur,
        { [styles.preloadBlurLoading]: isLoading },
        { [styles.loadDone]: !isLoading },
      )}
    >
      {render({ onLoad: handleLoad })}
    </div>
  );
};
