export const categories = [
  {
    imageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/imageFile-1711574436060.png',
    scImageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/scImageFile-1711574436162.png',
    masterGameSubCategoryId: 214,
    name: {
      EN: 'TOP GAMES',
    },
    masterGameCategoryId: 533,
    thumbnailType: 'short',
    isActive: true,
    orderId: 5,
    showInHeader: true,
    showInHamburgerMenu: true,
    createdAt: '2023-06-28T16:03:08.216Z',
    updatedAt: '2024-03-27T21:20:36.252Z',
    subCategoryGames: [
      {
        masterCasinoGameId: 1988,
        name: "Joker's Jewels Jackpot Play",
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnail-ZvlMo.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailHr-SXmLa.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailShort-tuSPZ.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailShortHr-SgsMj.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailLong-JjOvo.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailLongHr-Axwrk.jpeg',
        fullScreen: false,
        identifier: 'vsprg5joker',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.113Z',
        updatedAt: '2024-04-07T12:52:00.988Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 20,
        name: 'Loco Habanero',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_20-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_20-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_20-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_20-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_20-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_20-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_20',
        description: '',
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9649,
          credit: 20,
          maxWin: 5500,
          hitRate: 0.316,
          volatilityInPercentage: 0.8,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-03-25T21:20:46.238Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2406,
        name: 'Mad Hit Wild Alice.',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnail-QXWDh.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailHr-WmIOh.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailShort-Jhray.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailShortHr-rlaod.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailLong-tcmPC.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailLongHr-zBtYy.jpeg',
        fullScreen: false,
        identifier: 'rp_627',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.36',
          credit: '10',
          maxWin: '6568',
          hitRate: '38.36',
          volatilityInPercentage: '51',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-02-29T12:11:00.141Z',
        updatedAt: '2024-03-25T21:21:04.378Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2405,
        name: 'Mad Hit Marlin Bonanza.',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnail-DQgGL.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailHr-NhaoD.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailShort-kEiBx.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailShortHr-dRcfY.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailLong-OqZkh.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailLongHr-FqXip.jpeg',
        fullScreen: false,
        identifier: 'rp_614',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.36',
          credit: '10',
          maxWin: '6568',
          hitRate: '38.36',
          volatilityInPercentage: '51',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-02-29T12:09:44.825Z',
        updatedAt: '2024-02-29T18:30:56.700Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1144,
        name: 'Sugar Rush',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20sugarrush-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20sugarrush-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20sugarrush-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20sugarrush-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20sugarrush-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20sugarrush-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs20sugarrush',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:35.772Z',
        updatedAt: '2024-03-07T16:39:26.080Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 69,
        name: 'Diamond Explosion 7s',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnail-oBxKk.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailHr-DdPvj.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailShort-soPaf.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailShortHr-IaEax.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailLong-Kidne.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailLongHr-sMKeK.png',
        fullScreen: false,
        identifier: 'rp_504',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9682,
          credit: 50,
          maxWin: 8000,
          hitRate: 0.4824,
          volatilityInPercentage: 0.8,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-04-07T10:02:13.159Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1276,
        name: 'Floating Dragon',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10floatdrg-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10floatdrg-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10floatdrg-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10floatdrg-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10floatdrg-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10floatdrg-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs10floatdrg',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:38.287Z',
        updatedAt: '2024-03-25T21:17:13.168Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 22,
        name: 'Quest of Gods',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnail-ToGta.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailHr-GUeiM.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailShort-vFesu.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailShortHr-kLgaZ.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailLong-gjZha.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailLongHr-dtRsg.jpeg',
        fullScreen: false,
        identifier: 'rp_23',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9648,
          credit: 20,
          maxWin: 10000,
          hitRate: 0.235,
          volatilityInPercentage: 0.75,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-02-04T17:16:02.790Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 16,
        name: 'Immortal Empress',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnail-cthus.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailHr-LycYz.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailShort-MyFTc.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailShortHr-EVwVh.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailLong-SRbPJ.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailLongHr-gRUfc.jpeg',
        fullScreen: false,
        identifier: 'rp_16',
        description: null,
        volatilityRating: '5',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9618,
          credit: 50,
          maxWin: 10400,
          hitRate: 0.308,
          volatilityInPercentage: 0.85,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-02-19T12:15:43.835Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2538,
        name: 'Volcano Rising 94',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnail-vVQel.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailHr-Kacbt.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailShort-nMmte.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailShortHr-ZcSmO.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailLong-xMlgf.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailLongHr-SzcFv.png',
        fullScreen: false,
        identifier: 'rp_558',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.36',
          credit: '50',
          maxWin: '2188',
          hitRate: '45.16',
          volatilityInPercentage: '77',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-13T09:26:07.130Z',
        updatedAt: '2024-03-13T09:39:03.056Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 73,
        name: 'Native Spirit',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_76',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9637,
          credit: 10,
          maxWin: 1578,
          hitRate: 0.2854,
          volatilityInPercentage: 0.65,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-27T13:15:11.710Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 41,
        name: 'Elephant Stampede',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_42',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9691,
          credit: 50,
          maxWin: 1900,
          hitRate: 0.3131,
          volatilityInPercentage: 0.75,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-30T11:30:30.075Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 28,
        name: 'Mayan Cache',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_501',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9647,
          credit: 20,
          maxWin: 2300,
          hitRate: 0.2728,
          volatilityInPercentage: 0.65,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-27T13:32:25.065Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 23,
        name: 'Shake Shake Money Tree',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_25-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_25-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_25-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_25-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_25-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_25-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_522',
        description: null,
        volatilityRating: '2',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9648,
          credit: 20,
          maxWin: 1100,
          hitRate: 0.321,
          volatilityInPercentage: 0.45,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-30T11:35:41.223Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2039,
        name: 'Mad Hit Oasis',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_601-thumbnail-otIIW.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_601-thumbnailHr-AFXJH.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_601-thumbnailShort-enyVn.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_601-thumbnailShortHr-bmvby.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_601-thumbnailLong-BuwFV.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_601-thumbnailLongHr-hMIgq.png',
        fullScreen: false,
        identifier: 'rp_601',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 94.36,
          credit: 10,
          maxWin: 5481,
          hitRate: 38.36,
          volatilityInPercentage: 51,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-11-23T11:30:29.492Z',
        updatedAt: '2023-11-27T12:23:04.494Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 43,
        name: 'Rush Fever 7s',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_44',
        description: null,
        volatilityRating: '2',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.969,
          credit: 50,
          maxWin: 8600,
          hitRate: 0.383,
          volatilityInPercentage: 0.4,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-02-15T11:26:58.445Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 51,
        name: 'Vegas No Limit Wins',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_507-thumbnail-eNshC.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_507-thumbnailHr-owqwq.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_507-thumbnailShort-uDTGm.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_507-thumbnailShortHr-rtYZJ.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_507-thumbnailLong-ydqaN.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_507-thumbnailLongHr-ivljo.png',
        fullScreen: false,
        identifier: 'rp_507',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9688,
          credit: 50,
          maxWin: 4260,
          hitRate: 0.3123,
          volatilityInPercentage: 0.75,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-30T11:28:17.772Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1143,
        name: 'Gates of Olympus',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20olympgate-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20olympgate-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20olympgate-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20olympgate-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20olympgate-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20olympgate-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs20olympgate',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:35.740Z',
        updatedAt: '2024-03-25T21:18:13.219Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2038,
        name: 'Stampede Rush Wicked',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_592-thumbnail-Yraeo.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_592-thumbnailHr-ENzRh.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_592-thumbnailShort-EobRJ.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_592-thumbnailShortHr-ycCix.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_592-thumbnailLong-potpd.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_592-thumbnailLongHr-henDY.png',
        fullScreen: false,
        identifier: 'rp_592',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 94.31,
          credit: 10,
          maxWin: 1338,
          hitRate: 45.24,
          volatilityInPercentage: 52,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-11-23T11:30:29.492Z',
        updatedAt: '2023-11-27T12:21:46.590Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1444,
        name: 'Voodoo Magic',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs40voodoo-thumbnail-JhQFr.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs40voodoo-thumbnailHr-PRRlP.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs40voodoo-thumbnailShort-snnIH.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs40voodoo-thumbnailShortHr-xCbIZ.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs40voodoo-thumbnailLong-PUtYE.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs40voodoo-thumbnailLongHr-TUBCW.png',
        fullScreen: false,
        identifier: 'vs40voodoo',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:40.314Z',
        updatedAt: '2024-03-25T21:21:59.641Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
    ],
    hasMoreGames: true,
  },
  {
    imageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/imageFile-1712054506087.png',
    scImageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/scImageFile-1712054506209.png',
    masterGameSubCategoryId: 224,
    name: {
      EN: 'NEW RELEASES',
    },
    masterGameCategoryId: 533,
    thumbnailType: 'short',
    isActive: true,
    orderId: 7,
    showInHeader: true,
    showInHamburgerMenu: false,
    createdAt: '2023-07-31T09:45:33.766Z',
    updatedAt: '2024-04-03T14:56:02.350Z',
    subCategoryGames: [
      {
        masterCasinoGameId: 2907,
        name: 'Sugar Rush 1000',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sugarrushx-thumbnail-qGtCf.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sugarrushx-thumbnailHr-Ejwgg.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sugarrushx-thumbnailShort-FYUim.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sugarrushx-thumbnailShortHr-XpiWF.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sugarrushx-thumbnailLong-bntKo.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sugarrushx-thumbnailLongHr-xnIaP.jpeg',
        fullScreen: false,
        identifier: 'vs20sugarrushx',
        description: null,
        volatilityRating: '5',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.5',
          credit: '20',
          maxWin: '25000',
          hitRate: '34.4',
          volatilityInPercentage: '70',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-04-01T18:00:37.763Z',
        updatedAt: '2024-04-04T10:08:37.988Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2908,
        name: 'Beware The Deep Megaways',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbewaretd-thumbnail-LNcZP.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbewaretd-thumbnailHr-eeolX.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbewaretd-thumbnailShort-awwgC.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbewaretd-thumbnailShortHr-sNsuj.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbewaretd-thumbnailLong-hxBcX.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbewaretd-thumbnailLongHr-ZIgFe.jpeg',
        fullScreen: false,
        identifier: 'vswaysbewaretd',
        description: null,
        volatilityRating: '5',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.48',
          credit: '20',
          maxWin: '10000',
          hitRate: '25',
          volatilityInPercentage: '70',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-04-01T18:00:55.182Z',
        updatedAt: '2024-04-04T10:08:16.579Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2905,
        name: 'Bull Fever',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_49-thumbnail-RSCRT.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_49-thumbnailHr-JAVCK.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_49-thumbnailShort-YQnfW.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_49-thumbnailShortHr-YpDgM.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_49-thumbnailLong-Uwkji.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_49-thumbnailLongHr-wLTLX.jpeg',
        fullScreen: false,
        identifier: 'rp_49',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '96.88',
          credit: '50',
          maxWin: '4000',
          hitRate: '33.79',
          volatilityInPercentage: '75',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-04-01T18:00:09.728Z',
        updatedAt: '2024-04-04T10:51:49.161Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2906,
        name: 'Immortal Ways Diamonds',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_531-thumbnail-pbgAO.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_531-thumbnailHr-UXuCg.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_531-thumbnailShort-lJNpZ.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_531-thumbnailShortHr-BZLXK.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_531-thumbnailLong-agaHN.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_531-thumbnailLongHr-DEtZI.jpeg',
        fullScreen: false,
        identifier: 'rp_531',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.28',
          credit: '25',
          maxWin: '4030',
          hitRate: '53.17',
          volatilityInPercentage: '47',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-04-01T18:00:22.350Z',
        updatedAt: '2024-04-04T10:08:56.238Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2704,
        name: '3 Dancing Monkeys',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmonkey-thumbnail-tkkZm.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmonkey-thumbnailHr-hbwGt.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmonkey-thumbnailShort-SZJoN.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmonkey-thumbnailShortHr-haWUr.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmonkey-thumbnailLong-mrbFm.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmonkey-thumbnailLongHr-PvbpB.jpeg',
        fullScreen: false,
        identifier: 'vswaysmonkey',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94',
          credit: '30',
          maxWin: '12077',
          hitRate: '36.49',
          volatilityInPercentage: '70',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-23T13:29:17.749Z',
        updatedAt: '2024-03-27T10:13:12.681Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2839,
        name: 'Immortall Ways Buffalo',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_534-thumbnail-xZzJp.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_534-thumbnailHr-ijSWx.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_534-thumbnailShort-ZFxVI.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_534-thumbnailShortHr-ztxeW.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_534-thumbnailLong-hmHBZ.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_534-thumbnailLongHr-BBWdU.jpeg',
        fullScreen: false,
        identifier: 'rp_534',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.28',
          credit: '25',
          maxWin: '4030',
          hitRate: '53.17',
          volatilityInPercentage: '47',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-25T14:18:49.648Z',
        updatedAt: '2024-03-27T08:58:24.061Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2738,
        name: 'Go High Gone Fishing',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_639-thumbnail-FfSVa.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_639-thumbnailHr-KoEvB.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_639-thumbnailShort-ILIQy.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_639-thumbnailShortHr-FreUX.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_639-thumbnailLong-DxZWy.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_639-thumbnailLongHr-rEcgy.jpeg',
        fullScreen: false,
        identifier: 'rp_639',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.29',
          credit: '10',
          maxWin: '2890',
          hitRate: '44.25',
          volatilityInPercentage: '61',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-24T07:33:07.641Z',
        updatedAt: '2024-03-25T10:12:53.777Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2737,
        name: 'Sheerluck 94',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_645-thumbnail-LwJJy.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_645-thumbnailHr-rTXVU.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_645-thumbnailShort-FEZYd.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_645-thumbnailShortHr-Jwfwp.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_645-thumbnailLong-egMlu.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_645-thumbnailLongHr-GlVeG.jpeg',
        fullScreen: false,
        identifier: 'rp_645',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.47',
          credit: '20',
          maxWin: '2295',
          hitRate: '27.11',
          volatilityInPercentage: '61',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-24T07:32:48.693Z',
        updatedAt: '2024-03-25T10:12:56.959Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1480,
        name: 'Mysterious',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs4096mystery-thumbnail-LdjyH.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs4096mystery-thumbnailHr-LjnTG.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs4096mystery-thumbnailShort-aRDwa.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs4096mystery-thumbnailShortHr-GlwGc.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs4096mystery-thumbnailLong-spPob.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs4096mystery-thumbnailLongHr-WFQwQ.jpeg',
        fullScreen: false,
        identifier: 'vs4096mystery',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:40.887Z',
        updatedAt: '2024-03-21T11:52:47.186Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 31,
        name: 'Sun of Ra',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_34-thumbnail-zLuFq.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_34-thumbnailHr-aOrDv.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_34-thumbnailShort-vucpi.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_34-thumbnailShortHr-dEOhx.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_34-thumbnailLong-UYkrj.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_34-thumbnailLongHr-FvmVK.jpeg',
        fullScreen: false,
        identifier: 'rp_34',
        description: '',
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.969,
          credit: 50,
          maxWin: 4200,
          hitRate: 0.304,
          volatilityInPercentage: 0.75,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-03-21T11:52:31.295Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 64,
        name: 'Mayan Blaze',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_65-thumbnail-mYijx.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_65-thumbnailHr-vvHXW.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_65-thumbnailShort-UiKOk.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_65-thumbnailShortHr-MibWp.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_65-thumbnailLong-bxibe.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_65-thumbnailLongHr-LYXZc.jpeg',
        fullScreen: false,
        identifier: 'rp_65',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.964,
          credit: 20,
          maxWin: 900,
          hitRate: 0.326,
          volatilityInPercentage: 0.45,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-03-21T11:52:39.250Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2505,
        name: 'Pot of Fortune',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20stckwldsc-thumbnail-LFdLk.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20stckwldsc-thumbnailHr-WinHX.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20stckwldsc-thumbnailShort-mobjz.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20stckwldsc-thumbnailShortHr-rDhLt.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20stckwldsc-thumbnailLong-DCMwE.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20stckwldsc-thumbnailLongHr-qPWMs.jpeg',
        fullScreen: false,
        identifier: 'vs20stckwldsc',
        description: null,
        volatilityRating: '5',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94',
          credit: '20',
          maxWin: '5000',
          hitRate: '33.33',
          volatilityInPercentage: '70',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-10T16:18:28.830Z',
        updatedAt: '2024-03-21T10:04:59.802Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2472,
        name: "Wheel O'Gold",
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20multiup-thumbnail-JVPgb.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20multiup-thumbnailHr-psjXp.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20multiup-thumbnailShort-BToOM.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20multiup-thumbnailShortHr-UtnDs.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20multiup-thumbnailLong-YuVwH.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20multiup-thumbnailLongHr-rilVm.jpeg',
        fullScreen: false,
        identifier: 'vs20multiup',
        description: null,
        volatilityRating: '5',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.02',
          credit: '20',
          maxWin: '10000',
          hitRate: '33.33',
          volatilityInPercentage: '70',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-07T17:30:20.317Z',
        updatedAt: '2024-03-21T10:03:50.170Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 50,
        name: "Adventures of Li'l Red",
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_51-thumbnail-jPDbC.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_51-thumbnailHr-WOEpg.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_51-thumbnailShort-uehFI.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_51-thumbnailShortHr-KhiPJ.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_51-thumbnailLong-xWYhF.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_51-thumbnailLongHr-XCQNQ.png',
        fullScreen: false,
        identifier: 'rp_51',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.969,
          credit: 50,
          maxWin: 2800,
          hitRate: 0.3463,
          volatilityInPercentage: 0.75,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-03-07T11:16:59.850Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 49,
        name: 'Enter the Vault',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_50-thumbnail-vNpxb.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_50-thumbnailHr-BWSCg.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_50-thumbnailShort-ZqVfQ.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_50-thumbnailShortHr-jlpgi.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_50-thumbnailLong-EGdKX.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_50-thumbnailLongHr-vCjUK.jpeg',
        fullScreen: false,
        identifier: 'rp_50',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9682,
          credit: 50,
          maxWin: 8000,
          hitRate: 0.4824,
          volatilityInPercentage: 0.8,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-03-07T11:16:52.059Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2372,
        name: 'Diamond_Explosion_Sweet_love',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_605-thumbnail-KIqWf.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_605-thumbnailHr-lHKhc.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_605-thumbnailShort-ZtzIP.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_605-thumbnailShortHr-XQfHx.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_605-thumbnailLong-EDIdO.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_605-thumbnailLongHr-NFQZU.jpeg',
        fullScreen: false,
        identifier: 'rp_605',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 94.45,
          credit: 0.5,
          maxWin: 8087,
          hitRate: 47.34,
          volatilityInPercentage: 47,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-02-08T08:27:57.851Z',
        updatedAt: '2024-02-19T12:14:21.663Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2406,
        name: 'Mad Hit Wild Alice.',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnail-QXWDh.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailHr-WmIOh.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailShort-Jhray.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailShortHr-rlaod.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailLong-tcmPC.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailLongHr-zBtYy.jpeg',
        fullScreen: false,
        identifier: 'rp_627',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.36',
          credit: '10',
          maxWin: '6568',
          hitRate: '38.36',
          volatilityInPercentage: '51',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-02-29T12:11:00.141Z',
        updatedAt: '2024-03-25T21:21:04.378Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1319,
        name: 'Queen of Gods',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10egrich-thumbnail-ufNst.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10egrich-thumbnailHr-UYdQo.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10egrich-thumbnailShort-ZrroJ.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10egrich-thumbnailShortHr-PZYYq.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10egrich-thumbnailLong-tgzif.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10egrich-thumbnailLongHr-aLnDu.jpeg',
        fullScreen: false,
        identifier: 'vs10egrich',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:38.973Z',
        updatedAt: '2024-03-06T14:31:45.643Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2405,
        name: 'Mad Hit Marlin Bonanza.',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnail-DQgGL.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailHr-NhaoD.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailShort-kEiBx.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailShortHr-dRcfY.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailLong-OqZkh.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailLongHr-FqXip.jpeg',
        fullScreen: false,
        identifier: 'rp_614',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.36',
          credit: '10',
          maxWin: '6568',
          hitRate: '38.36',
          volatilityInPercentage: '51',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-02-29T12:09:44.825Z',
        updatedAt: '2024-02-29T18:30:56.700Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2228,
        name: 'Book of Power',
        masterCasinoProviderId: 562,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/bookofpower-thumbnail-iiGKL.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/bookofpower-thumbnailHr-higdM.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/bookofpower-thumbnailShort-MgCyn.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/bookofpower-thumbnailShortHr-BdUQO.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/bookofpower-thumbnailLong-qzSJk.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/bookofpower-thumbnailLongHr-QGTco.jpeg',
        fullScreen: false,
        identifier: 'bookofpower',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 96.11,
          credit: 10,
          maxWin: 900000,
          hitRate: 45.24,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-01-04T15:45:07.527Z',
        updatedAt: '2024-02-19T12:14:42.077Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 562,
          name: 'Relax Gaming',
          isActive: true,
          thumbnailUrl: null,
          masterGameAggregatorId: null,
          uuid: 'dd239001-e721-4fc3-a02b-d215a7229e89',
          createdAt: '2023-12-12T14:19:15.699Z',
          updatedAt: '2024-01-18T16:58:10.881Z',
        },
        gameLiveTables: [],
      },
    ],
    hasMoreGames: true,
  },
  {
    imageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/imageFile-1711562337561.png',
    scImageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/scImageFile-1711562337786.png',
    masterGameSubCategoryId: 215,
    name: {
      EN: 'JACKPOT',
    },
    masterGameCategoryId: 533,
    thumbnailType: 'short',
    isActive: true,
    orderId: 6,
    showInHeader: true,
    showInHamburgerMenu: false,
    createdAt: '2023-06-28T16:05:24.029Z',
    updatedAt: '2024-04-03T14:55:47.308Z',
    subCategoryGames: [
      {
        masterCasinoGameId: 1977,
        name: 'Big Bass Bonanza Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnail-AaCjf.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailHr-SLEYZ.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailShort-WcWPn.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailShortHr-ULMZa.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailLong-ObMtP.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailLongHr-fBwTz.png',
        fullScreen: false,
        identifier: 'vsprg10bbbnza',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:40.839Z',
        updatedAt: '2024-04-07T12:52:00.900Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1986,
        name: 'Gates of Olympus Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20olympus',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.078Z',
        updatedAt: '2024-04-07T12:52:00.968Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1980,
        name: 'Crown of Fire Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg10cfire',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:40.921Z',
        updatedAt: '2024-04-07T12:52:00.909Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1987,
        name: 'Hot to Burn Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg5hburn',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.097Z',
        updatedAt: '2024-04-07T12:52:00.978Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1984,
        name: 'Fire Hot 5 Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5firehot-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5firehot-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5firehot-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5firehot-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5firehot-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5firehot-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg5firehot',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.055Z',
        updatedAt: '2024-04-07T12:52:00.953Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1988,
        name: "Joker's Jewels Jackpot Play",
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnail-ZvlMo.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailHr-SXmLa.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailShort-tuSPZ.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailShortHr-SgsMj.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailLong-JjOvo.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailLongHr-Axwrk.jpeg',
        fullScreen: false,
        identifier: 'vsprg5joker',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.113Z',
        updatedAt: '2024-04-07T12:52:00.988Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1995,
        name: 'Sugar Rush Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20sugarush',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.230Z',
        updatedAt: '2024-04-07T12:52:01.079Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1998,
        name: 'The Dog House Megaways Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprgwaysdogs',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.341Z',
        updatedAt: '2024-04-07T12:52:01.095Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1996,
        name: 'Sweet Bonanza Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitsw-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitsw-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitsw-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitsw-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitsw-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitsw-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20fruitsw',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.320Z',
        updatedAt: '2024-04-07T12:52:01.084Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1993,
        name: 'Starlight Princess Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20starpr-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20starpr-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20starpr-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20starpr-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20starpr-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20starpr-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20starpr',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.217Z',
        updatedAt: '2024-04-07T12:52:01.069Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1994,
        name: 'Striking Hot 5 Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5strh-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5strh-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5strh-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5strh-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5strh-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5strh-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg5strh',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.224Z',
        updatedAt: '2024-04-07T12:52:01.074Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1992,
        name: 'Shining Hot 5 Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5sh-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5sh-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5sh-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5sh-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5sh-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5sh-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg5sh',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.210Z',
        updatedAt: '2024-04-07T12:52:01.063Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1985,
        name: 'Fruit Party Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitpty-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitpty-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitpty-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitpty-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitpty-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitpty-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20fruitpty',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.069Z',
        updatedAt: '2024-04-07T12:52:00.963Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1981,
        name: 'Fire Hot 100 Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg100firehot-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg100firehot-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg100firehot-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg100firehot-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg100firehot-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg100firehot-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg100firehot',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:40.931Z',
        updatedAt: '2024-04-07T12:52:00.922Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1997,
        name: 'The Dog House Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20doghouse-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20doghouse-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20doghouse-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20doghouse-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20doghouse-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20doghouse-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20doghouse',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.332Z',
        updatedAt: '2024-04-07T12:52:01.089Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1982,
        name: 'Fire Hot 20 Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fh-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fh-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fh-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fh-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fh-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fh-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20fh',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:40.963Z',
        updatedAt: '2024-04-07T12:52:00.929Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1983,
        name: 'Fire Hot 40 Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg40firehot-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg40firehot-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg40firehot-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg40firehot-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg40firehot-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg40firehot-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg40firehot',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.041Z',
        updatedAt: '2024-04-07T12:52:00.941Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1989,
        name: 'Shining Hot 100 Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg100sh-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg100sh-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg100sh-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg100sh-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg100sh-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg100sh-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg100sh',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.130Z',
        updatedAt: '2024-04-07T12:52:01.012Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1990,
        name: 'Shining Hot 20 Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sh-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sh-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sh-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sh-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sh-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sh-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20sh',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.138Z',
        updatedAt: '2024-04-07T12:52:01.032Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1991,
        name: 'Shining Hot 40 Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg40sh-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg40sh-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg40sh-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg40sh-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg40sh-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg40sh-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg40sh',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.145Z',
        updatedAt: '2024-04-07T12:52:01.042Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
    ],
    hasMoreGames: false,
  },
  {
    imageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/imageFile-1711562386889.png',
    scImageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/scImageFile-1711562386990.png',
    masterGameSubCategoryId: 481,
    name: {
      EN: 'MEGAWAYS',
    },
    masterGameCategoryId: 533,
    thumbnailType: 'short',
    isActive: true,
    orderId: 9,
    showInHeader: false,
    showInHamburgerMenu: true,
    createdAt: '2024-02-29T10:42:37.742Z',
    updatedAt: '2024-03-27T17:59:47.082Z',
    subCategoryGames: [
      {
        masterCasinoGameId: 1998,
        name: 'The Dog House Megaways Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprgwaysdogs',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.341Z',
        updatedAt: '2024-04-07T12:52:01.095Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1159,
        name: 'Power of Thor Megaways',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswayshammthor-thumbnail-RvqOk.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswayshammthor-thumbnailHr-NNxVa.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswayshammthor-thumbnailShort-umjev.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswayshammthor-thumbnailShortHr-ryhHw.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswayshammthor-thumbnailLong-oVhYc.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswayshammthor-thumbnailLongHr-TFQwQ.jpeg',
        fullScreen: false,
        identifier: 'vswayshammthor',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:35.969Z',
        updatedAt: '2024-03-13T10:08:53.672Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1165,
        name: 'Buffalo King Megaways',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnail-zDSeS.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailHr-wpaFP.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailShort-KDelq.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailShortHr-QQzJx.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailLong-SbkNf.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailLongHr-XEjmU.png',
        fullScreen: false,
        identifier: 'vswaysbufking',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:36.038Z',
        updatedAt: '2024-03-13T10:06:34.351Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1180,
        name: 'Madame Destiny Megaways',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmadame-thumbnail-NbOZE.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmadame-thumbnailHr-pdkJg.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmadame-thumbnailShort-OTiFt.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmadame-thumbnailShortHr-tFElD.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmadame-thumbnailLong-QLRlC.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmadame-thumbnailLongHr-qlREu.png',
        fullScreen: false,
        identifier: 'vswaysmadame',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:36.281Z',
        updatedAt: '2024-03-13T10:09:34.694Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1147,
        name: '5 Lions Megaways',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswayslions-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswayslions-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswayslions-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswayslions-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswayslions-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswayslions-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vswayslions',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:35.793Z',
        updatedAt: '2023-09-21T11:28:53.760Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1164,
        name: 'Great Rhino Megaways',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswaysrhino-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswaysrhino-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswaysrhino-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswaysrhino-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswaysrhino-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswaysrhino-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vswaysrhino',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:36.029Z',
        updatedAt: '2023-09-21T11:32:58.318Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1149,
        name: 'The Dog House Megaways',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswaysdogs-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswaysdogs-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswaysdogs-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswaysdogs-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswaysdogs-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vswaysdogs-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vswaysdogs',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:35.815Z',
        updatedAt: '2023-09-21T11:07:03.687Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1297,
        name: 'Christmas Carol Megaways',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20xmascarol-thumbnail-HpSgW.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20xmascarol-thumbnailHr-MrUyn.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20xmascarol-thumbnailShort-EzKQb.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20xmascarol-thumbnailShortHr-jhcNv.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20xmascarol-thumbnailLong-CmBMX.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20xmascarol-thumbnailLongHr-Uxqee.png',
        fullScreen: false,
        identifier: 'vs20xmascarol',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:38.666Z',
        updatedAt: '2023-12-28T18:13:30.914Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1209,
        name: 'Muertos Multiplier Megaways',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20muertos-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20muertos-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20muertos-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20muertos-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20muertos-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20muertos-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs20muertos',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:36.979Z',
        updatedAt: '2023-09-21T11:51:41.343Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
    ],
    hasMoreGames: false,
  },
  {
    imageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/imageFile-1711562360180.png',
    scImageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/scImageFile-1711562360261.png',
    masterGameSubCategoryId: 482,
    name: {
      EN: 'LUCKY SEVENS',
    },
    masterGameCategoryId: 533,
    thumbnailType: 'short',
    isActive: true,
    orderId: 10,
    showInHeader: false,
    showInHamburgerMenu: true,
    createdAt: '2024-02-29T10:48:17.836Z',
    updatedAt: '2024-03-27T17:59:20.439Z',
    subCategoryGames: [
      {
        masterCasinoGameId: 71,
        name: 'Rush Fever 7s Deluxe',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_73-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_73-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_73-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_73-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_73-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_73-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_510',
        description: null,
        volatilityRating: '2',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9656,
          credit: 50,
          maxWin: 4501,
          hitRate: 0.3859,
          volatilityInPercentage: 0.4,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-27T13:27:33.535Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 69,
        name: 'Diamond Explosion 7s',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnail-oBxKk.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailHr-DdPvj.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailShort-soPaf.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailShortHr-IaEax.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailLong-Kidne.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailLongHr-sMKeK.png',
        fullScreen: false,
        identifier: 'rp_504',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9682,
          credit: 50,
          maxWin: 8000,
          hitRate: 0.4824,
          volatilityInPercentage: 0.8,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-04-07T10:02:13.159Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 43,
        name: 'Rush Fever 7s',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_44',
        description: null,
        volatilityRating: '2',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.969,
          credit: 50,
          maxWin: 8600,
          hitRate: 0.383,
          volatilityInPercentage: 0.4,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-02-15T11:26:58.445Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2186,
        name: 'Juicy Fruits Multihold',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnail-PJaan.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailHr-gvuVn.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailShort-yGkVS.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailShortHr-jCwsi.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailLong-toDya.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailLongHr-OrNMf.png',
        fullScreen: false,
        identifier: 'vs50jfmulthold',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-12-26T10:04:45.410Z',
        updatedAt: '2024-01-22T19:24:07.216Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1987,
        name: 'Hot to Burn Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg5hburn',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.097Z',
        updatedAt: '2024-04-07T12:52:00.978Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1980,
        name: 'Crown of Fire Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg10cfire',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:40.921Z',
        updatedAt: '2024-04-07T12:52:00.909Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1433,
        name: 'Hot to Burn',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs5hotburn-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs5hotburn-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs5hotburn-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs5hotburn-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs5hotburn-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs5hotburn-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs5hotburn',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:40.232Z',
        updatedAt: '2023-09-21T11:34:51.789Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1306,
        name: 'Fire Strike',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10firestrike-thumbnail-XSxEt.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10firestrike-thumbnailHr-UWwPg.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10firestrike-thumbnailShort-hblfQ.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10firestrike-thumbnailShortHr-tnXdL.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10firestrike-thumbnailLong-Kywfb.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10firestrike-thumbnailLongHr-Lveyx.png',
        fullScreen: false,
        identifier: 'vs10firestrike',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:38.787Z',
        updatedAt: '2024-03-13T10:07:32.969Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
    ],
    hasMoreGames: false,
  },
  {
    imageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/imageFile-1711573828130.png',
    scImageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/scImageFile-1711573828243.png',
    masterGameSubCategoryId: 485,
    name: {
      EN: 'WILD SPINS',
    },
    masterGameCategoryId: 533,
    thumbnailType: 'short',
    isActive: true,
    orderId: 11,
    showInHeader: false,
    showInHamburgerMenu: true,
    createdAt: '2024-02-29T12:36:11.525Z',
    updatedAt: '2024-03-27T21:10:28.374Z',
    subCategoryGames: [
      {
        masterCasinoGameId: 2184,
        name: 'Fire Stampede',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysstampede-thumbnail-XkFvu.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysstampede-thumbnailHr-JAwkj.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysstampede-thumbnailShort-hXISw.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysstampede-thumbnailShortHr-gcHHj.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysstampede-thumbnailLong-VoCFJ.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysstampede-thumbnailLongHr-IqoRi.png',
        fullScreen: false,
        identifier: 'vswaysstampede',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-12-26T10:04:45.328Z',
        updatedAt: '2024-03-25T21:16:47.867Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1165,
        name: 'Buffalo King Megaways',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnail-zDSeS.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailHr-wpaFP.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailShort-KDelq.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailShortHr-QQzJx.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailLong-SbkNf.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailLongHr-XEjmU.png',
        fullScreen: false,
        identifier: 'vswaysbufking',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:36.038Z',
        updatedAt: '2024-03-13T10:06:34.351Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 41,
        name: 'Elephant Stampede',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_42',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9691,
          credit: 50,
          maxWin: 1900,
          hitRate: 0.3131,
          volatilityInPercentage: 0.75,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-30T11:30:30.075Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2283,
        name: 'The Great Pigsby',
        masterCasinoProviderId: 562,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/thegreatpigsby-thumbnail-mgHSs.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/thegreatpigsby-thumbnailHr-KKDka.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/thegreatpigsby-thumbnailShort-tZMoC.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/thegreatpigsby-thumbnailShortHr-udSob.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/thegreatpigsby-thumbnailLong-avmFJ.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/thegreatpigsby-thumbnailLongHr-SrQZO.png',
        fullScreen: false,
        identifier: 'thegreatpigsby',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 96.55,
          credit: 10,
          maxWin: 162000,
          hitRate: 45.24,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-01-04T15:45:07.527Z',
        updatedAt: '2024-01-18T08:22:58.471Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 562,
          name: 'Relax Gaming',
          isActive: true,
          thumbnailUrl: null,
          masterGameAggregatorId: null,
          uuid: 'dd239001-e721-4fc3-a02b-d215a7229e89',
          createdAt: '2023-12-12T14:19:15.699Z',
          updatedAt: '2024-01-18T16:58:10.881Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2277,
        name: 'Shark Wash',
        masterCasinoProviderId: 562,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/sharkwash-thumbnail-zScOs.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/sharkwash-thumbnailHr-eWaCk.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/sharkwash-thumbnailShort-qeXvj.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/sharkwash-thumbnailShortHr-hnETh.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/sharkwash-thumbnailLong-wkoxd.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/sharkwash-thumbnailLongHr-NYzAI.jpeg',
        fullScreen: false,
        identifier: 'sharkwash',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 96.06,
          credit: 10,
          maxWin: 1000000,
          hitRate: 45.24,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-01-04T15:45:07.527Z',
        updatedAt: '2024-02-01T08:28:06.806Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 562,
          name: 'Relax Gaming',
          isActive: true,
          thumbnailUrl: null,
          masterGameAggregatorId: null,
          uuid: 'dd239001-e721-4fc3-a02b-d215a7229e89',
          createdAt: '2023-12-12T14:19:15.699Z',
          updatedAt: '2024-01-18T16:58:10.881Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 28,
        name: 'Mayan Cache',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_501',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9647,
          credit: 20,
          maxWin: 2300,
          hitRate: 0.2728,
          volatilityInPercentage: 0.65,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-27T13:32:25.065Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1998,
        name: 'The Dog House Megaways Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprgwaysdogs-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprgwaysdogs',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.341Z',
        updatedAt: '2024-04-07T12:52:01.095Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1977,
        name: 'Big Bass Bonanza Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnail-AaCjf.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailHr-SLEYZ.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailShort-WcWPn.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailShortHr-ULMZa.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailLong-ObMtP.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailLongHr-fBwTz.png',
        fullScreen: false,
        identifier: 'vsprg10bbbnza',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:40.839Z',
        updatedAt: '2024-04-07T12:52:00.900Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1275,
        name: 'Big Bass Splash',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10txbigbass-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10txbigbass-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10txbigbass-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10txbigbass-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10txbigbass-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10txbigbass-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs10txbigbass',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:38.280Z',
        updatedAt: '2023-09-21T12:03:06.565Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1270,
        name: 'Bigger Bass Blizzard - Christmas Catch',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs12bbbxmas-thumbnail-eTcEu.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs12bbbxmas-thumbnailHr-fGdgf.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs12bbbxmas-thumbnailShort-PfEMT.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs12bbbxmas-thumbnailShortHr-vNalA.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs12bbbxmas-thumbnailLong-Mhovf.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs12bbbxmas-thumbnailLongHr-mTiTu.png',
        fullScreen: false,
        identifier: 'vs12bbbxmas',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:38.191Z',
        updatedAt: '2023-12-28T18:11:49.137Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1257,
        name: 'Big Bass Bonanza - Keeping it Reel',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10bbkir-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10bbkir-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10bbkir-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10bbkir-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10bbkir-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10bbkir-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs10bbkir',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:37.875Z',
        updatedAt: '2024-03-25T21:19:19.711Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
    ],
    hasMoreGames: false,
  },
  {
    imageUrl: 'https://cdn.crowncoinscasino.com/production/assets/sub-category/thumbnail-483.png',
    scImageUrl: 'https://cdn.crowncoinscasino.com/production/assets/sub-category/scThumbnail-483.png',
    masterGameSubCategoryId: 483,
    name: {
      EN: 'CANDY CRAZE',
    },
    masterGameCategoryId: 533,
    thumbnailType: 'short',
    isActive: true,
    orderId: 12,
    showInHeader: false,
    showInHamburgerMenu: true,
    createdAt: '2024-02-29T10:51:47.056Z',
    updatedAt: '2024-03-20T14:02:04.156Z',
    subCategoryGames: [
      {
        masterCasinoGameId: 1146,
        name: 'Sweet Bonanza',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20fruitsw-thumbnail-zuDAa.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20fruitsw-thumbnailHr-awNQw.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20fruitsw-thumbnailShort-Qblah.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20fruitsw-thumbnailShortHr-ROQBW.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20fruitsw-thumbnailLong-cuFPK.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20fruitsw-thumbnailLongHr-Mpwca.png',
        fullScreen: false,
        identifier: 'vs20fruitsw',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:35.787Z',
        updatedAt: '2024-03-13T10:08:16.093Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1995,
        name: 'Sugar Rush Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20sugarush',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.230Z',
        updatedAt: '2024-04-07T12:52:01.079Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1985,
        name: 'Fruit Party Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitpty-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitpty-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitpty-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitpty-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitpty-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitpty-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20fruitpty',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.069Z',
        updatedAt: '2024-04-07T12:52:00.963Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1996,
        name: 'Sweet Bonanza Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitsw-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitsw-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitsw-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitsw-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitsw-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20fruitsw-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20fruitsw',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.320Z',
        updatedAt: '2024-04-07T12:52:01.084Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2186,
        name: 'Juicy Fruits Multihold',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnail-PJaan.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailHr-gvuVn.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailShort-yGkVS.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailShortHr-jCwsi.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailLong-toDya.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailLongHr-OrNMf.png',
        fullScreen: false,
        identifier: 'vs50jfmulthold',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-12-26T10:04:45.410Z',
        updatedAt: '2024-01-22T19:24:07.216Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1158,
        name: 'Fruit Party',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20fruitparty-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20fruitparty-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20fruitparty-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20fruitparty-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20fruitparty-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20fruitparty-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs20fruitparty',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:35.934Z',
        updatedAt: '2024-03-25T21:17:35.858Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1353,
        name: 'Juicy Fruits',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs50juicyfr-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs50juicyfr-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs50juicyfr-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs50juicyfr-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs50juicyfr-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs50juicyfr-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs50juicyfr',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:39.459Z',
        updatedAt: '2023-09-21T11:01:37.500Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2080,
        name: 'Sugar Rush Xmas',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sugrux-thumbnail-qpybT.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sugrux-thumbnailHr-OKLtH.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sugrux-thumbnailShort-cyltt.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sugrux-thumbnailShortHr-boCOq.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sugrux-thumbnailLong-Wdwas.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sugrux-thumbnailLongHr-NMZOR.png',
        fullScreen: false,
        identifier: 'vs20sugrux',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-11-27T13:46:12.642Z',
        updatedAt: '2024-03-28T13:25:41.174Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
    ],
    hasMoreGames: false,
  },
  {
    imageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/imageFile-1711562299367.png',
    scImageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/scImageFile-1711562299647.png',
    masterGameSubCategoryId: 484,
    name: {
      EN: 'GODS OF FORTUNE',
    },
    masterGameCategoryId: 533,
    thumbnailType: 'short',
    isActive: true,
    orderId: 13,
    showInHeader: false,
    showInHamburgerMenu: true,
    createdAt: '2024-02-29T10:54:20.204Z',
    updatedAt: '2024-03-27T17:58:19.774Z',
    subCategoryGames: [
      {
        masterCasinoGameId: 2571,
        name: 'Zeus Rush Fever Deluxe 94',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_555-thumbnail-gSEDu.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_555-thumbnailHr-OwgAV.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_555-thumbnailShort-WLLGx.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_555-thumbnailShortHr-NIyYl.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_555-thumbnailLong-YneUB.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_555-thumbnailLongHr-ReuHV.png',
        fullScreen: false,
        identifier: 'rp_555',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.46',
          credit: '50',
          maxWin: '8085',
          hitRate: '38.56',
          volatilityInPercentage: '61',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-13T09:41:38.400Z',
        updatedAt: '2024-03-13T09:44:31.269Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2182,
        name: 'Gates of Olympus 1000',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20olympx-thumbnail-CsqOM.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20olympx-thumbnailHr-hMIBX.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20olympx-thumbnailShort-RLhvX.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20olympx-thumbnailShortHr-bRoUW.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20olympx-thumbnailLong-xyyDa.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20olympx-thumbnailLongHr-ftYjK.png',
        fullScreen: false,
        identifier: 'vs20olympx',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-12-26T10:04:45.274Z',
        updatedAt: '2024-03-25T21:18:34.546Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 22,
        name: 'Quest of Gods',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnail-ToGta.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailHr-GUeiM.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailShort-vFesu.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailShortHr-kLgaZ.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailLong-gjZha.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailLongHr-dtRsg.jpeg',
        fullScreen: false,
        identifier: 'rp_23',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9648,
          credit: 20,
          maxWin: 10000,
          hitRate: 0.235,
          volatilityInPercentage: 0.75,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-02-04T17:16:02.790Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 73,
        name: 'Native Spirit',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_76',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9637,
          credit: 10,
          maxWin: 1578,
          hitRate: 0.2854,
          volatilityInPercentage: 0.65,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-27T13:15:11.710Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2285,
        name: 'Titan Strike',
        masterCasinoProviderId: 562,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/titanstrike-thumbnail-FggFH.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/titanstrike-thumbnailHr-LyUlo.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/titanstrike-thumbnailShort-inPUb.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/titanstrike-thumbnailShortHr-dGGkm.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/titanstrike-thumbnailLong-qefoe.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/titanstrike-thumbnailLongHr-QWcti.png',
        fullScreen: false,
        identifier: 'titanstrike',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 96.09,
          credit: 10,
          maxWin: 900900,
          hitRate: 45.24,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-01-04T15:45:07.527Z',
        updatedAt: '2024-01-11T16:06:30.309Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 562,
          name: 'Relax Gaming',
          isActive: true,
          thumbnailUrl: null,
          masterGameAggregatorId: null,
          uuid: 'dd239001-e721-4fc3-a02b-d215a7229e89',
          createdAt: '2023-12-12T14:19:15.699Z',
          updatedAt: '2024-01-18T16:58:10.881Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1986,
        name: 'Gates of Olympus Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20olympus',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.078Z',
        updatedAt: '2024-04-07T12:52:00.968Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1167,
        name: 'Zeus vs Hades - Gods of War',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs15godsofwar-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs15godsofwar-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs15godsofwar-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs15godsofwar-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs15godsofwar-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs15godsofwar-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs15godsofwar',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:36.071Z',
        updatedAt: '2023-10-08T17:27:07.712Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1159,
        name: 'Power of Thor Megaways',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswayshammthor-thumbnail-RvqOk.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswayshammthor-thumbnailHr-NNxVa.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswayshammthor-thumbnailShort-umjev.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswayshammthor-thumbnailShortHr-ryhHw.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswayshammthor-thumbnailLong-oVhYc.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswayshammthor-thumbnailLongHr-TFQwQ.jpeg',
        fullScreen: false,
        identifier: 'vswayshammthor',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:35.969Z',
        updatedAt: '2024-03-13T10:08:53.672Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1143,
        name: 'Gates of Olympus',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20olympgate-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20olympgate-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20olympgate-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20olympgate-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20olympgate-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20olympgate-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs20olympgate',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:35.740Z',
        updatedAt: '2024-03-25T21:18:13.219Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 14,
        name: 'Dragoness',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_14-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_14-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_14-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_14-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_14-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_14-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_14',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9633,
          credit: 20,
          maxWin: 5500,
          hitRate: 0.252,
          volatilityInPercentage: 0.8,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-27T13:25:11.890Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 38,
        name: 'Zeus Rush Fever',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_38-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_38-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_38-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_38-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_38-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_38-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_38',
        description: null,
        volatilityRating: '2',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.969,
          credit: 50,
          maxWin: 8600,
          hitRate: 0.383,
          volatilityInPercentage: 0.4,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-27T12:29:04.762Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
    ],
    hasMoreGames: false,
  },
  {
    imageUrl: 'https://cdn.crowncoinscasino.com/production/assets/game-category/PRAGMATIC/imageFile-1711381293051.png',
    scImageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/PRAGMATIC/scImageFile-1711381293281.png',
    masterGameSubCategoryId: 624,
    name: {
      EN: 'PRAGMATIC',
    },
    masterGameCategoryId: 533,
    thumbnailType: 'short',
    isActive: true,
    orderId: null,
    showInHeader: false,
    showInHamburgerMenu: true,
    createdAt: '2024-03-25T15:41:33.407Z',
    updatedAt: '2024-04-03T14:56:12.610Z',
    subCategoryGames: [
      {
        masterCasinoGameId: 2704,
        name: '3 Dancing Monkeys',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmonkey-thumbnail-tkkZm.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmonkey-thumbnailHr-hbwGt.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmonkey-thumbnailShort-SZJoN.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmonkey-thumbnailShortHr-haWUr.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmonkey-thumbnailLong-mrbFm.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysmonkey-thumbnailLongHr-PvbpB.jpeg',
        fullScreen: false,
        identifier: 'vswaysmonkey',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94',
          credit: '30',
          maxWin: '12077',
          hitRate: '36.49',
          volatilityInPercentage: '70',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-23T13:29:17.749Z',
        updatedAt: '2024-03-27T10:13:12.681Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1986,
        name: 'Gates of Olympus Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20olympus-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20olympus',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.078Z',
        updatedAt: '2024-04-07T12:52:00.968Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2184,
        name: 'Fire Stampede',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysstampede-thumbnail-XkFvu.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysstampede-thumbnailHr-JAwkj.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysstampede-thumbnailShort-hXISw.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysstampede-thumbnailShortHr-gcHHj.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysstampede-thumbnailLong-VoCFJ.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysstampede-thumbnailLongHr-IqoRi.png',
        fullScreen: false,
        identifier: 'vswaysstampede',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-12-26T10:04:45.328Z',
        updatedAt: '2024-03-25T21:16:47.867Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2336,
        name: 'Good Luck & Good Fortune',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10luckfort-thumbnail-arSUh.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10luckfort-thumbnailHr-oZTnU.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10luckfort-thumbnailShort-JUmuj.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10luckfort-thumbnailShortHr-KhzPH.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10luckfort-thumbnailLong-vJukr.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10luckfort-thumbnailLongHr-KoDdp.jpeg',
        fullScreen: false,
        identifier: 'vs10luckfort',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-01-25T09:26:47.904Z',
        updatedAt: '2024-03-25T21:20:29.520Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1276,
        name: 'Floating Dragon',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10floatdrg-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10floatdrg-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10floatdrg-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10floatdrg-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10floatdrg-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10floatdrg-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs10floatdrg',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:38.287Z',
        updatedAt: '2024-03-25T21:17:13.168Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1995,
        name: 'Sugar Rush Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg20sugarush-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg20sugarush',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.230Z',
        updatedAt: '2024-04-07T12:52:01.079Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1386,
        name: 'Money Roll',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/cs5moneyroll-thumbnail-pECQn.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/cs5moneyroll-thumbnailHr-oyzNT.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/cs5moneyroll-thumbnailShort-fmsWa.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/cs5moneyroll-thumbnailShortHr-BwKum.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/cs5moneyroll-thumbnailLong-llyYX.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/cs5moneyroll-thumbnailLongHr-riBOO.png',
        fullScreen: false,
        identifier: 'cs5moneyroll',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:39.804Z',
        updatedAt: '2024-02-26T14:33:02.127Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1988,
        name: "Joker's Jewels Jackpot Play",
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnail-ZvlMo.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailHr-SXmLa.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailShort-tuSPZ.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailShortHr-SgsMj.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailLong-JjOvo.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vsprg5joker-thumbnailLongHr-Axwrk.jpeg',
        fullScreen: false,
        identifier: 'vsprg5joker',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.113Z',
        updatedAt: '2024-04-07T12:52:00.988Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1977,
        name: 'Big Bass Bonanza Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnail-AaCjf.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailHr-SLEYZ.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailShort-WcWPn.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailShortHr-ULMZa.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailLong-ObMtP.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10bbbnza-thumbnailLongHr-fBwTz.png',
        fullScreen: false,
        identifier: 'vsprg10bbbnza',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:40.839Z',
        updatedAt: '2024-04-07T12:52:00.900Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1480,
        name: 'Mysterious',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs4096mystery-thumbnail-LdjyH.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs4096mystery-thumbnailHr-LjnTG.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs4096mystery-thumbnailShort-aRDwa.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs4096mystery-thumbnailShortHr-GlwGc.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs4096mystery-thumbnailLong-spPob.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs4096mystery-thumbnailLongHr-WFQwQ.jpeg',
        fullScreen: false,
        identifier: 'vs4096mystery',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:40.887Z',
        updatedAt: '2024-03-21T11:52:47.186Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2472,
        name: "Wheel O'Gold",
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20multiup-thumbnail-JVPgb.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20multiup-thumbnailHr-psjXp.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20multiup-thumbnailShort-BToOM.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20multiup-thumbnailShortHr-UtnDs.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20multiup-thumbnailLong-YuVwH.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20multiup-thumbnailLongHr-rilVm.jpeg',
        fullScreen: false,
        identifier: 'vs20multiup',
        description: null,
        volatilityRating: '5',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.02',
          credit: '20',
          maxWin: '10000',
          hitRate: '33.33',
          volatilityInPercentage: '70',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-07T17:30:20.317Z',
        updatedAt: '2024-03-21T10:03:50.170Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1165,
        name: 'Buffalo King Megaways',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnail-zDSeS.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailHr-wpaFP.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailShort-KDelq.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailShortHr-QQzJx.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailLong-SbkNf.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysbufking-thumbnailLongHr-XEjmU.png',
        fullScreen: false,
        identifier: 'vswaysbufking',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:36.038Z',
        updatedAt: '2024-03-13T10:06:34.351Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2505,
        name: 'Pot of Fortune',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20stckwldsc-thumbnail-LFdLk.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20stckwldsc-thumbnailHr-WinHX.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20stckwldsc-thumbnailShort-mobjz.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20stckwldsc-thumbnailShortHr-rDhLt.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20stckwldsc-thumbnailLong-DCMwE.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20stckwldsc-thumbnailLongHr-qPWMs.jpeg',
        fullScreen: false,
        identifier: 'vs20stckwldsc',
        description: null,
        volatilityRating: '5',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94',
          credit: '20',
          maxWin: '5000',
          hitRate: '33.33',
          volatilityInPercentage: '70',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-10T16:18:28.830Z',
        updatedAt: '2024-03-21T10:04:59.802Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1980,
        name: 'Crown of Fire Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg10cfire-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg10cfire',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:40.921Z',
        updatedAt: '2024-04-07T12:52:00.909Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1144,
        name: 'Sugar Rush',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20sugarrush-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20sugarrush-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20sugarrush-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20sugarrush-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20sugarrush-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20sugarrush-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs20sugarrush',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:35.772Z',
        updatedAt: '2024-03-07T16:39:26.080Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1987,
        name: 'Hot to Burn Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5hburn-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg5hburn',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.097Z',
        updatedAt: '2024-04-07T12:52:00.978Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1306,
        name: 'Fire Strike',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10firestrike-thumbnail-XSxEt.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10firestrike-thumbnailHr-UWwPg.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10firestrike-thumbnailShort-hblfQ.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10firestrike-thumbnailShortHr-tnXdL.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10firestrike-thumbnailLong-Kywfb.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10firestrike-thumbnailLongHr-Lveyx.png',
        fullScreen: false,
        identifier: 'vs10firestrike',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:38.787Z',
        updatedAt: '2024-03-13T10:07:32.969Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1275,
        name: 'Big Bass Splash',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10txbigbass-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10txbigbass-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10txbigbass-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10txbigbass-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10txbigbass-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10txbigbass-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs10txbigbass',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:38.280Z',
        updatedAt: '2023-09-21T12:03:06.565Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1984,
        name: 'Fire Hot 5 Jackpot Play',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5firehot-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5firehot-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5firehot-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5firehot-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5firehot-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vsprg5firehot-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vsprg5firehot',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          jackpotFeedGC: 55550197.04,
          jackpotFeedSC: 25302.06,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-10-09T13:56:41.055Z',
        updatedAt: '2024-04-07T12:52:00.953Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1369,
        name: 'Wild Wild Riches',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs576treasures-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs576treasures-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs576treasures-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs576treasures-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs576treasures-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs576treasures-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs576treasures',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:39.614Z',
        updatedAt: '2023-09-21T11:53:41.220Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
    ],
    hasMoreGames: true,
  },
  {
    imageUrl: 'https://cdn.crowncoinscasino.com/production/assets/game-category/RUBYPLAY/imageFile-1711400312244.png',
    scImageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/RUBYPLAY/scImageFile-1711400312586.png',
    masterGameSubCategoryId: 690,
    name: {
      EN: 'RUBY PLAY',
    },
    masterGameCategoryId: 533,
    thumbnailType: 'short',
    isActive: true,
    orderId: null,
    showInHeader: false,
    showInHamburgerMenu: true,
    createdAt: '2024-03-25T20:58:32.715Z',
    updatedAt: '2024-04-03T14:56:28.354Z',
    subCategoryGames: [
      {
        masterCasinoGameId: 2406,
        name: 'Mad Hit Wild Alice.',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnail-QXWDh.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailHr-WmIOh.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailShort-Jhray.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailShortHr-rlaod.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailLong-tcmPC.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_627-thumbnailLongHr-zBtYy.jpeg',
        fullScreen: false,
        identifier: 'rp_627',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.36',
          credit: '10',
          maxWin: '6568',
          hitRate: '38.36',
          volatilityInPercentage: '51',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-02-29T12:11:00.141Z',
        updatedAt: '2024-03-25T21:21:04.378Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2538,
        name: 'Volcano Rising 94',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnail-vVQel.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailHr-Kacbt.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailShort-nMmte.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailShortHr-ZcSmO.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailLong-xMlgf.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailLongHr-SzcFv.png',
        fullScreen: false,
        identifier: 'rp_558',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.36',
          credit: '50',
          maxWin: '2188',
          hitRate: '45.16',
          volatilityInPercentage: '77',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-13T09:26:07.130Z',
        updatedAt: '2024-03-13T09:39:03.056Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 22,
        name: 'Quest of Gods',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnail-ToGta.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailHr-GUeiM.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailShort-vFesu.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailShortHr-kLgaZ.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailLong-gjZha.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_23-thumbnailLongHr-dtRsg.jpeg',
        fullScreen: false,
        identifier: 'rp_23',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9648,
          credit: 20,
          maxWin: 10000,
          hitRate: 0.235,
          volatilityInPercentage: 0.75,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-02-04T17:16:02.790Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2038,
        name: 'Stampede Rush Wicked',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_592-thumbnail-Yraeo.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_592-thumbnailHr-ENzRh.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_592-thumbnailShort-EobRJ.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_592-thumbnailShortHr-ycCix.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_592-thumbnailLong-potpd.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_592-thumbnailLongHr-henDY.png',
        fullScreen: false,
        identifier: 'rp_592',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 94.31,
          credit: 10,
          maxWin: 1338,
          hitRate: 45.24,
          volatilityInPercentage: 52,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-11-23T11:30:29.492Z',
        updatedAt: '2023-11-27T12:21:46.590Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 31,
        name: 'Sun of Ra',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_34-thumbnail-zLuFq.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_34-thumbnailHr-aOrDv.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_34-thumbnailShort-vucpi.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_34-thumbnailShortHr-dEOhx.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_34-thumbnailLong-UYkrj.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_34-thumbnailLongHr-FvmVK.jpeg',
        fullScreen: false,
        identifier: 'rp_34',
        description: '',
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.969,
          credit: 50,
          maxWin: 4200,
          hitRate: 0.304,
          volatilityInPercentage: 0.75,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-03-21T11:52:31.295Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 64,
        name: 'Mayan Blaze',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_65-thumbnail-mYijx.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_65-thumbnailHr-vvHXW.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_65-thumbnailShort-UiKOk.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_65-thumbnailShortHr-MibWp.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_65-thumbnailLong-bxibe.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_65-thumbnailLongHr-LYXZc.jpeg',
        fullScreen: false,
        identifier: 'rp_65',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.964,
          credit: 20,
          maxWin: 900,
          hitRate: 0.326,
          volatilityInPercentage: 0.45,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-03-21T11:52:39.250Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 23,
        name: 'Shake Shake Money Tree',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_25-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_25-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_25-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_25-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_25-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_25-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_522',
        description: null,
        volatilityRating: '2',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9648,
          credit: 20,
          maxWin: 1100,
          hitRate: 0.321,
          volatilityInPercentage: 0.45,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-30T11:35:41.223Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 20,
        name: 'Loco Habanero',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_20-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_20-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_20-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_20-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_20-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_20-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_20',
        description: '',
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9649,
          credit: 20,
          maxWin: 5500,
          hitRate: 0.316,
          volatilityInPercentage: 0.8,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-03-25T21:20:46.238Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 69,
        name: 'Diamond Explosion 7s',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnail-oBxKk.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailHr-DdPvj.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailShort-soPaf.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailShortHr-IaEax.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailLong-Kidne.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_504-thumbnailLongHr-sMKeK.png',
        fullScreen: false,
        identifier: 'rp_504',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9682,
          credit: 50,
          maxWin: 8000,
          hitRate: 0.4824,
          volatilityInPercentage: 0.8,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-04-07T10:02:13.159Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 73,
        name: 'Native Spirit',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_76-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_76',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9637,
          credit: 10,
          maxWin: 1578,
          hitRate: 0.2854,
          volatilityInPercentage: 0.65,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-27T13:15:11.710Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 41,
        name: 'Elephant Stampede',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_42-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_42',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9691,
          credit: 50,
          maxWin: 1900,
          hitRate: 0.3131,
          volatilityInPercentage: 0.75,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-30T11:30:30.075Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 33,
        name: 'Shake Shake Leprechaun',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_35-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_35-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_35-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_35-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_35-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_35-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_516',
        description: null,
        volatilityRating: '2',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9648,
          credit: 20,
          maxWin: 1100,
          hitRate: 0.321,
          volatilityInPercentage: 0.45,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-30T11:32:43.837Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 28,
        name: 'Mayan Cache',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_32-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_501',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9647,
          credit: 20,
          maxWin: 2300,
          hitRate: 0.2728,
          volatilityInPercentage: 0.65,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-27T13:32:25.065Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 50,
        name: "Adventures of Li'l Red",
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_51-thumbnail-jPDbC.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_51-thumbnailHr-WOEpg.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_51-thumbnailShort-uehFI.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_51-thumbnailShortHr-KhiPJ.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_51-thumbnailLong-xWYhF.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_51-thumbnailLongHr-XCQNQ.png',
        fullScreen: false,
        identifier: 'rp_51',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.969,
          credit: 50,
          maxWin: 2800,
          hitRate: 0.3463,
          volatilityInPercentage: 0.75,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-03-07T11:16:59.850Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 16,
        name: 'Immortal Empress',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnail-cthus.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailHr-LycYz.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailShort-MyFTc.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailShortHr-EVwVh.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailLong-SRbPJ.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailLongHr-gRUfc.jpeg',
        fullScreen: false,
        identifier: 'rp_16',
        description: null,
        volatilityRating: '5',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9618,
          credit: 50,
          maxWin: 10400,
          hitRate: 0.308,
          volatilityInPercentage: 0.85,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-02-19T12:15:43.835Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2405,
        name: 'Mad Hit Marlin Bonanza.',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnail-DQgGL.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailHr-NhaoD.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailShort-kEiBx.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailShortHr-dRcfY.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailLong-OqZkh.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_614-thumbnailLongHr-FqXip.jpeg',
        fullScreen: false,
        identifier: 'rp_614',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.36',
          credit: '10',
          maxWin: '6568',
          hitRate: '38.36',
          volatilityInPercentage: '51',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-02-29T12:09:44.825Z',
        updatedAt: '2024-02-29T18:30:56.700Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2039,
        name: 'Mad Hit Oasis',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_601-thumbnail-otIIW.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_601-thumbnailHr-AFXJH.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_601-thumbnailShort-enyVn.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_601-thumbnailShortHr-bmvby.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_601-thumbnailLong-BuwFV.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_601-thumbnailLongHr-hMIgq.png',
        fullScreen: false,
        identifier: 'rp_601',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 94.36,
          credit: 10,
          maxWin: 5481,
          hitRate: 38.36,
          volatilityInPercentage: 51,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-11-23T11:30:29.492Z',
        updatedAt: '2023-11-27T12:23:04.494Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 43,
        name: 'Rush Fever 7s',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_44-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_44',
        description: null,
        volatilityRating: '2',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.969,
          credit: 50,
          maxWin: 8600,
          hitRate: 0.383,
          volatilityInPercentage: 0.4,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-02-15T11:26:58.445Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 71,
        name: 'Rush Fever 7s Deluxe',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_73-thumbnail.png',
        thumbnailHrUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_73-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_73-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_73-thumbnailShortHr.png',
        thumbnailLongUrl: 'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_73-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/ruby-play/rp_73-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'rp_510',
        description: null,
        volatilityRating: '2',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9656,
          credit: 50,
          maxWin: 4501,
          hitRate: 0.3859,
          volatilityInPercentage: 0.4,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-11-27T13:27:33.535Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 49,
        name: 'Enter the Vault',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_50-thumbnail-vNpxb.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_50-thumbnailHr-BWSCg.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_50-thumbnailShort-ZqVfQ.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_50-thumbnailShortHr-jlpgi.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_50-thumbnailLong-EGdKX.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_50-thumbnailLongHr-vCjUK.jpeg',
        fullScreen: false,
        identifier: 'rp_50',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9682,
          credit: 50,
          maxWin: 8000,
          hitRate: 0.4824,
          volatilityInPercentage: 0.8,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-03-07T11:16:52.059Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
    ],
    hasMoreGames: true,
  },
  {
    imageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/imageFile-1711562441169.png',
    scImageUrl:
      'https://cdn.crowncoinscasino.com/production/assets/game-category/[objectObject]/scImageFile-1711562441278.png',
    masterGameSubCategoryId: 213,
    name: {
      EN: 'SLOTS',
    },
    masterGameCategoryId: 533,
    thumbnailType: 'short',
    isActive: true,
    orderId: 14,
    showInHeader: true,
    showInHamburgerMenu: true,
    createdAt: '2023-06-28T16:01:34.496Z',
    updatedAt: '2024-03-27T18:00:41.423Z',
    subCategoryGames: [
      {
        masterCasinoGameId: 2538,
        name: 'Volcano Rising 94',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnail-vVQel.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailHr-Kacbt.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailShort-nMmte.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailShortHr-ZcSmO.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailLong-xMlgf.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_558-thumbnailLongHr-SzcFv.png',
        fullScreen: false,
        identifier: 'rp_558',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.36',
          credit: '50',
          maxWin: '2188',
          hitRate: '45.16',
          volatilityInPercentage: '77',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-13T09:26:07.130Z',
        updatedAt: '2024-03-13T09:39:03.056Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2372,
        name: 'Diamond_Explosion_Sweet_love',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_605-thumbnail-KIqWf.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_605-thumbnailHr-lHKhc.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_605-thumbnailShort-ZtzIP.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_605-thumbnailShortHr-XQfHx.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_605-thumbnailLong-EDIdO.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_605-thumbnailLongHr-NFQZU.jpeg',
        fullScreen: false,
        identifier: 'rp_605',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 94.45,
          credit: 0.5,
          maxWin: 8087,
          hitRate: 47.34,
          volatilityInPercentage: 47,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-02-08T08:27:57.851Z',
        updatedAt: '2024-02-19T12:14:21.663Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2228,
        name: 'Book of Power',
        masterCasinoProviderId: 562,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/bookofpower-thumbnail-iiGKL.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/bookofpower-thumbnailHr-higdM.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/bookofpower-thumbnailShort-MgCyn.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/bookofpower-thumbnailShortHr-BdUQO.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/bookofpower-thumbnailLong-qzSJk.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/bookofpower-thumbnailLongHr-QGTco.jpeg',
        fullScreen: false,
        identifier: 'bookofpower',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 96.11,
          credit: 10,
          maxWin: 900000,
          hitRate: 45.24,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-01-04T15:45:07.527Z',
        updatedAt: '2024-02-19T12:14:42.077Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 562,
          name: 'Relax Gaming',
          isActive: true,
          thumbnailUrl: null,
          masterGameAggregatorId: null,
          uuid: 'dd239001-e721-4fc3-a02b-d215a7229e89',
          createdAt: '2023-12-12T14:19:15.699Z',
          updatedAt: '2024-01-18T16:58:10.881Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1525,
        name: 'Romeo and Juliet',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs25romeoandjuliet-thumbnail-Wennv.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs25romeoandjuliet-thumbnailHr-SenTr.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs25romeoandjuliet-thumbnailShort-zQMzB.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs25romeoandjuliet-thumbnailShortHr-kFalK.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs25romeoandjuliet-thumbnailLong-yKCQH.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs25romeoandjuliet-thumbnailLongHr-onalc.jpeg',
        fullScreen: false,
        identifier: 'vs25romeoandjuliet',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:41.278Z',
        updatedAt: '2024-02-19T12:15:00.553Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1474,
        name: 'Fairytale Fortune',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs15fairytale-thumbnail-SXiml.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs15fairytale-thumbnailHr-urtjp.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs15fairytale-thumbnailShort-JFRYG.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs15fairytale-thumbnailShortHr-kxcIO.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs15fairytale-thumbnailLong-mlDMy.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs15fairytale-thumbnailLongHr-ofpMb.jpeg',
        fullScreen: false,
        identifier: 'vs15fairytale',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:40.834Z',
        updatedAt: '2024-03-25T21:16:19.028Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1386,
        name: 'Money Roll',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/cs5moneyroll-thumbnail-pECQn.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/cs5moneyroll-thumbnailHr-oyzNT.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/cs5moneyroll-thumbnailShort-fmsWa.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/cs5moneyroll-thumbnailShortHr-BwKum.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/cs5moneyroll-thumbnailLong-llyYX.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/cs5moneyroll-thumbnailLongHr-riBOO.png',
        fullScreen: false,
        identifier: 'cs5moneyroll',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:39.804Z',
        updatedAt: '2024-02-26T14:33:02.127Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1148,
        name: 'Sweet Bonanza Xmas',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sbxmas-thumbnail-wwobr.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sbxmas-thumbnailHr-LtTeJ.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sbxmas-thumbnailShort-ASqaT.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sbxmas-thumbnailShortHr-FpMHC.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sbxmas-thumbnailLong-enFtS.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20sbxmas-thumbnailLongHr-hyWxF.png',
        fullScreen: false,
        identifier: 'vs20sbxmas',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:35.808Z',
        updatedAt: '2024-03-11T09:15:42.224Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1319,
        name: 'Queen of Gods',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10egrich-thumbnail-ufNst.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10egrich-thumbnailHr-UYdQo.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10egrich-thumbnailShort-ZrroJ.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10egrich-thumbnailShortHr-PZYYq.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10egrich-thumbnailLong-tgzif.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10egrich-thumbnailLongHr-aLnDu.jpeg',
        fullScreen: false,
        identifier: 'vs10egrich',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:38.973Z',
        updatedAt: '2024-03-06T14:31:45.643Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 16,
        name: 'Immortal Empress',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnail-cthus.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailHr-LycYz.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailShort-MyFTc.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailShortHr-EVwVh.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailLong-SRbPJ.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_16-thumbnailLongHr-gRUfc.jpeg',
        fullScreen: false,
        identifier: 'rp_16',
        description: null,
        volatilityRating: '5',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9618,
          credit: 50,
          maxWin: 10400,
          hitRate: 0.308,
          volatilityInPercentage: 0.85,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-02-19T12:15:43.835Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 27,
        name: 'Arcane Beauties',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_24-thumbnail-zmjkm.jpeg',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_24-thumbnailHr-eTxVS.jpeg',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_24-thumbnailShort-FcBul.jpeg',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_24-thumbnailShortHr-JaRTs.jpeg',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_24-thumbnailLong-AEFDH.jpeg',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_24-thumbnailLongHr-zkMNm.jpeg',
        fullScreen: false,
        identifier: 'rp_24',
        description: null,
        volatilityRating: '2',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9648,
          credit: 20,
          maxWin: 600,
          hitRate: 0.358,
          volatilityInPercentage: 0.35,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2024-02-19T12:15:21.734Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2186,
        name: 'Juicy Fruits Multihold',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnail-PJaan.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailHr-gvuVn.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailShort-yGkVS.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailShortHr-jCwsi.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailLong-toDya.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs50jfmulthold-thumbnailLongHr-OrNMf.png',
        fullScreen: false,
        identifier: 'vs50jfmulthold',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-12-26T10:04:45.410Z',
        updatedAt: '2024-01-22T19:24:07.216Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2183,
        name: 'Floating Dragon New Year Festival',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysfltdrgny-thumbnail-BTRen.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysfltdrgny-thumbnailHr-nwkqR.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysfltdrgny-thumbnailShort-CXamT.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysfltdrgny-thumbnailShortHr-dHUdN.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysfltdrgny-thumbnailLong-AWrHT.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vswaysfltdrgny-thumbnailLongHr-AgXQx.png',
        fullScreen: false,
        identifier: 'vswaysfltdrgny',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-12-26T10:04:45.317Z',
        updatedAt: '2024-03-25T21:20:02.778Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2285,
        name: 'Titan Strike',
        masterCasinoProviderId: 562,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/titanstrike-thumbnail-FggFH.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/titanstrike-thumbnailHr-LyUlo.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/titanstrike-thumbnailShort-inPUb.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/titanstrike-thumbnailShortHr-dGGkm.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/titanstrike-thumbnailLong-qefoe.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/relax-gaming/titanstrike-thumbnailLongHr-QWcti.png',
        fullScreen: false,
        identifier: 'titanstrike',
        description: null,
        volatilityRating: '3',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 96.09,
          credit: 10,
          maxWin: 900900,
          hitRate: 45.24,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-01-04T15:45:07.527Z',
        updatedAt: '2024-01-11T16:06:30.309Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 562,
          name: 'Relax Gaming',
          isActive: true,
          thumbnailUrl: null,
          masterGameAggregatorId: null,
          uuid: 'dd239001-e721-4fc3-a02b-d215a7229e89',
          createdAt: '2023-12-12T14:19:15.699Z',
          updatedAt: '2024-01-18T16:58:10.881Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2571,
        name: 'Zeus Rush Fever Deluxe 94',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_555-thumbnail-gSEDu.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_555-thumbnailHr-OwgAV.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_555-thumbnailShort-WLLGx.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_555-thumbnailShortHr-NIyYl.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_555-thumbnailLong-YneUB.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_555-thumbnailLongHr-ReuHV.png',
        fullScreen: false,
        identifier: 'rp_555',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: '94.46',
          credit: '50',
          maxWin: '8085',
          hitRate: '38.56',
          volatilityInPercentage: '61',
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2024-03-13T09:41:38.400Z',
        updatedAt: '2024-03-13T09:44:31.269Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 2079,
        name: 'Big Bass Christmas Bash',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10bbsplxmas-thumbnail-eRamr.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10bbsplxmas-thumbnailHr-cKjqh.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10bbsplxmas-thumbnailShort-wIGec.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10bbsplxmas-thumbnailShortHr-GtDjH.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10bbsplxmas-thumbnailLong-ESXpj.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10bbsplxmas-thumbnailLongHr-zAAyr.png',
        fullScreen: false,
        identifier: 'vs10bbsplxmas',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-11-27T13:46:12.625Z',
        updatedAt: '2023-12-28T15:02:28.616Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1473,
        name: 'The Magic Cauldron',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20magicpot-thumbnail-dtism.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20magicpot-thumbnailHr-ZbbhE.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20magicpot-thumbnailShort-kCgED.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs20magicpot-thumbnailShortHr-BoVVq.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20magicpot-thumbnailLong-cTkBf.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs20magicpot-thumbnailLongHr-OboRT.png',
        fullScreen: false,
        identifier: 'vs20magicpot',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:40.828Z',
        updatedAt: '2023-11-08T14:32:10.851Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 39,
        name: 'Christmas Fortune',
        masterCasinoProviderId: 74,
        masterGameSubCategoryId: null,
        thumbnailUrl: 'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_40-thumbnail-qgWec.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_40-thumbnailHr-oOZkD.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_40-thumbnailShort-etPmG.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_40-thumbnailShortHr-odJZZ.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_40-thumbnailLong-vGtXx.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/ruby-play/rp_40-thumbnailLongHr-VXFZW.png',
        fullScreen: false,
        identifier: 'rp_40',
        description: null,
        volatilityRating: '4',
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: {
          RTP: 0.9649,
          credit: 20,
          maxWin: 5500,
          hitRate: 0.316,
          volatilityInPercentage: 0.8,
        },
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-06-07T14:59:21.781Z',
        updatedAt: '2023-12-28T18:15:50.353Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 74,
          name: 'RubyPlay',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/74.png',
          masterGameAggregatorId: null,
          uuid: '1756cc63-1100-4e5c-9155-c54110252fb0',
          createdAt: '2023-06-07T14:57:32.149Z',
          updatedAt: '2024-03-20T13:06:27.063Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1167,
        name: 'Zeus vs Hades - Gods of War',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs15godsofwar-thumbnail.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs15godsofwar-thumbnailHr.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs15godsofwar-thumbnailShort.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs15godsofwar-thumbnailShortHr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs15godsofwar-thumbnailLong.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs15godsofwar-thumbnailLongHr.png',
        fullScreen: false,
        identifier: 'vs15godsofwar',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:36.071Z',
        updatedAt: '2023-10-08T17:27:07.712Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1444,
        name: 'Voodoo Magic',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs40voodoo-thumbnail-JhQFr.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs40voodoo-thumbnailHr-PRRlP.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs40voodoo-thumbnailShort-snnIH.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs40voodoo-thumbnailShortHr-xCbIZ.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs40voodoo-thumbnailLong-PUtYE.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs40voodoo-thumbnailLongHr-TUBCW.png',
        fullScreen: false,
        identifier: 'vs40voodoo',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:40.314Z',
        updatedAt: '2024-03-25T21:21:59.641Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
      {
        masterCasinoGameId: 1487,
        name: 'Vampires vs Wolves',
        masterCasinoProviderId: 283,
        masterGameSubCategoryId: null,
        thumbnailUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10vampwolf-thumbnail-PZOhB.png',
        thumbnailHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10vampwolf-thumbnailHr-ARIEk.png',
        thumbnailShortUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10vampwolf-thumbnailShort-Rupkf.png',
        thumbnailShortHrUrl:
          'https://cdn.crowncoinscasino.com/production/assets/games/pragmatic-play/vs10vampwolf-thumbnailShortHr-xMYQr.png',
        thumbnailLongUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10vampwolf-thumbnailLong-aRkKS.png',
        thumbnailLongHrUrl:
          'https://cdn.crowncoinscasino.com/production/games/assets/pragmatic-play/vs10vampwolf-thumbnailLongHr-itERU.png',
        fullScreen: false,
        identifier: 'vs10vampwolf',
        description: null,
        volatilityRating: null,
        hasFreespins: null,
        restrictions: null,
        theme: null,
        featureGroup: null,
        devices: null,
        lines: null,
        returnToPlayer: null,
        wageringContribution: 100,
        moreDetails: null,
        operatorStatus: true,
        isActive: true,
        orderId: null,
        isLive: false,
        createdAt: '2023-09-21T08:13:40.966Z',
        updatedAt: '2023-11-30T13:01:34.819Z',
        MasterCasinoProvider: {
          masterCasinoProviderId: 283,
          name: 'Pragmatic Play',
          isActive: true,
          thumbnailUrl: 'development/assets/provider/283.png',
          masterGameAggregatorId: null,
          uuid: 'f233fe60-1980-499d-878f-336bfb16dce9',
          createdAt: '2023-06-08T20:58:03.147Z',
          updatedAt: '2024-03-13T16:17:39.748Z',
        },
        gameLiveTables: [],
      },
    ],
    hasMoreGames: true,
  },
];
