export const ANIMATION_DURATION = 0.24; // seconds

export const animations = {
  opacity: {
    hidden: {
      opacity: 0,
      visibility: 'hidden',
    },
    visible: {
      opacity: 1,
      visibility: 'visible',
    },
    transition: {
      duration: ANIMATION_DURATION,
    },
  },
  scale: {
    hidden: { scale: 0.9 },
    visible: { scale: 1 },
    transition: { duration: ANIMATION_DURATION },
  },
  banner: {
    transition: { duration: 0 },
  },
  miniStore: {
    hidden: { y: '-100%', opacity: 0 },
    visible: { y: 0, opacity: 1 },
    transition: { duration: ANIMATION_DURATION },
  },
} as const;
