import { resolveFlag } from '../launchDarkly';

const effects = ['click', 'popup-open', 'popup-close', 'daily-bonus-open', 'toggle', 'new-tier-congrats'] as const;

export type SoundEffect = (typeof effects)[number];

const sounds: Map<SoundEffect, string> = new Map([
  ['click', '/sounds/click.mp3'],
  ['popup-open', '/sounds/popup-open.mp3'],
  ['popup-close', '/sounds/popup-close.mp3'],
  ['daily-bonus-open', '/sounds/daily-bonus-open.mp3'],
  ['toggle', '/sounds/toggle.mp3'],
  ['new-tier-congrats', '/sounds/new-tier-congrats.mp3'],
]);

class SoundEffects {
  private sounds: Map<SoundEffect, HTMLAudioElement> = new Map();

  currentAudioNode: HTMLAudioElement | null = null;

  constructor() {
    for (const [key, val] of sounds) {
      const audio = new Audio();
      audio.src = val;
      audio.autoplay = true;
      audio.muted = true;
      audio.load();
      this.sounds.set(key, audio);
    }
  }

  private async play() {
    if (this.currentAudioNode) {
      this.currentAudioNode.muted = false;
      await this.currentAudioNode.play();
      this.currentAudioNode.remove();
      this.currentAudioNode.addEventListener('ended', () => {
        this.currentAudioNode = null;
      });
    }
  }

  public playSound(name: SoundEffect, bypassFF = false) {
    const sound = this.sounds.get(name);
    try {
      const soundsEnabled = bypassFF || resolveFlag('sound_effects', false);
      if (sound && soundsEnabled) {
        const disabledByUser = localStorage.getItem('disable_sounds');
        if (!disabledByUser) {
          this.stopCurrentSound();
          this.currentAudioNode = sound.cloneNode() as HTMLAudioElement;
          void this.play();
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  public stopCurrentSound() {
    if (this.currentAudioNode) {
      this.currentAudioNode.pause();
      this.currentAudioNode.currentTime = 0;
      this.currentAudioNode = null;
    }
  }
}

const soundEffects = new SoundEffects();

export default soundEffects;
