import './Button.scss';
import { forwardRef, type ButtonHTMLAttributes, type ReactNode } from 'react';

import classnames from 'classnames';

import soundEffects from '../../services/sounds/soundEffects';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  classNameWrapper?: string;
  btnType?: 'base' | 'main' | 'light' | 'orange' | 'blue';
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  handleOnClick?: () => void;
}

// TODO: we need to change all buttons to new component
//  src/components/buttons/...
const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    { className, classNameWrapper = '', btnType = 'base', iconLeft, iconRight, handleOnClick, children, ...restProps },
    forwardedRef,
  ) => {
    const handleClick = () => {
      if (typeof handleOnClick === 'function') {
        handleOnClick();
      }
      soundEffects.playSound('click');
    };

    const buttonClasses = classnames('button', `button--${btnType}`, className);

    return (
      <div className={`animation-popup button-wrapper ${classNameWrapper}`}>
        <button className={buttonClasses} onClick={handleClick} {...restProps} ref={forwardedRef}>
          {iconLeft ? <div className="button__icon-side __left">{iconLeft}</div> : null}
          <div className="button__content">{children}</div>
          {iconRight ? <div className="button__icon-side __right">{iconRight}</div> : null}
        </button>
      </div>
    );
  },
);

Button.displayName = 'Button';

export default Button;
