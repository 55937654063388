import type { PropsWithChildren } from 'react';

import soundEffects from '../../services/sounds/soundEffects';

import styles from './Buttons.module.scss';

interface BlackButtonProps extends PropsWithChildren {
  onClick: () => void;
  withCrown?: boolean;
}

export default function BlackButton(props: BlackButtonProps) {
  const classes = ['animation-popup', styles.blackButton];

  if (props.withCrown) {
    classes.push(styles.withCrown);
  }

  const handleOnClick = () => {
    props.onClick();
    soundEffects.playSound('click');
  };

  return (
    <button className={classes.join(' ')} onClick={handleOnClick}>
      {props.children}
    </button>
  );
}
