import BlackButton from '../../../../components/buttons/BlackButton';
import { AppLogo } from '../../../../components/SVGIcons/AppLogo/AppLogo';
import type { HeaderProps } from '../types';

export function LandingHeader({ openSignUp }: HeaderProps) {
  return (
    <div className="home__hero-header">
      <AppLogo alwaysBlack />
      <div className="home__hero-header-controls">
        <BlackButton onClick={() => openSignUp?.()}>SIGN UP</BlackButton>
      </div>
    </div>
  );
}
