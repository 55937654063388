import type { PropsWithChildren } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { animations } from '../../config/animations';
import { useMultiResImage } from '../../hooks/useMultiResImage';

import styles from './BackgroundPattern.module.scss';
import highResPattern from './pattern.svg';
import lowResPattern from './pattern_lowres.png';

const availableColor = ['violet', 'green', 'yellow'] as const;

type Color = (typeof availableColor)[number];

const backgrounds: Record<Color, Pick<CSSStyleDeclaration, 'backgroundColor'>> = {
  violet: {
    backgroundColor: '#000',
  },
  green: {
    backgroundColor: '#5ED577',
  },
  yellow: {
    backgroundColor: '#ffca1e',
  },
};

const colors: Record<Color, Pick<CSSStyleDeclaration, 'filter'>> = {
  violet: {
    filter: 'invert(8%) sepia(27%) saturate(5362%) hue-rotate(256deg) brightness(82%) contrast(109%)',
  },
  green: {
    filter: 'invert(75%) sepia(57%) saturate(425%) hue-rotate(73deg) brightness(92%) contrast(92%)',
  },
  yellow: {
    filter: 'invert(98%) sepia(76%) saturate(2185%) hue-rotate(324deg) brightness(104%) contrast(97%)',
  },
};

interface Props extends PropsWithChildren {
  color: Color;
}

const patterns = [lowResPattern, highResPattern];

export default function BackgroundPattern(props: Props) {
  const pattern = useMultiResImage(patterns);

  return (
    <div className={styles.wrapper}>
      <AnimatePresence>
        <motion.div
          key={backgrounds[props.color].backgroundColor}
          animate={animations.opacity.visible}
          className={styles.background}
          exit={animations.opacity.hidden}
          initial={animations.opacity.hidden}
          style={backgrounds[props.color]}
          transition={animations.banner.transition}
        />
        <motion.div
          key={colors[props.color].filter}
          animate={animations.opacity.visible}
          className={styles.backgroundPattern}
          exit={animations.opacity.hidden}
          initial={animations.opacity.hidden}
          style={{ ...colors[props.color], backgroundImage: pattern && `url(${pattern})` }}
          transition={animations.banner.transition}
        />
      </AnimatePresence>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
}
