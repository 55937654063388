import './styles.scss';

import BackgroundPattern from '../../../components/backrounds/BackgroundPattern';
import Button from '../../../components/buttons/Button';
import { PreloadBlur } from '../../../components/PreloadBlur/PreloadBlur';

import { GenericBanner } from './banners/GenericBanner';
import { GamesBanner } from './components/GamesBanner';

const Landing: React.FC = () => {
  const navigateToSignUp = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('dialog', 'signup');
    window.open(`https://crowncoinscasino.com/?${searchParams.toString()}`);
  };

  return (
    <div className="home">
      <GenericBanner openSignUp={navigateToSignUp} />

      <BackgroundPattern color="violet">
        <div className="home__content">
          <GamesBanner openSignUp={navigateToSignUp} />
          <div className="home__content-purchase">
            <div className="home__content-purchase-title">NO PURCHASE necessary!</div>
            <div className="home__content-purchase-link" onClick={navigateToSignUp}>
              it&apos;s always <span>free</span> to play
            </div>
            <PreloadBlur
              className="home__content-purchase-hero"
              render={(props) => (
                <picture {...props}>
                  <source media="(min-width: 490px)" srcSet="/images/girl_with_phone2x.png" />
                  <img alt="Girl with a phone" loading="lazy" src="/images/girl_with_phone.png" />
                </picture>
              )}
            />
            <div className="home__content-purchase-button">
              <Button btnType="main" handleOnClick={navigateToSignUp}>
                FIND OUT MORE
              </Button>
            </div>
            <div className="home__content-purchase-link-second" onClick={navigateToSignUp}>
              CLAIM YOUR <span>FREE </span>SWEEP COIN NOW
            </div>
          </div>
        </div>
      </BackgroundPattern>
    </div>
  );
};

export default Landing;
