import { useCallback, useEffect, useRef, useState } from 'react';

export function useMultiResImage(sources: string[]): string | undefined;
export function useMultiResImage(): [string | undefined, (sources: string[]) => VoidFunction];
export function useMultiResImage(sources?: string[]) {
  const [currentSource, setCurrentSource] = useState<string>();
  const currentPriorityRef = useRef(0);

  const loadSources = useCallback((_sources: string[]) => {
    currentPriorityRef.current = 0;

    const unsubscribes = _sources.map((source, priority) => {
      const img = new Image();
      const handleLoad = () => {
        if (priority >= currentPriorityRef.current) {
          setCurrentSource(source);
          currentPriorityRef.current = priority;
        }
      };
      img.addEventListener('load', handleLoad);
      img.src = source;

      return () => {
        img.removeEventListener('load', handleLoad);
        img.remove();
      };
    });

    return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
  }, []);

  useEffect(() => {
    if (sources) {
      return loadSources(sources);
    }
  }, [loadSources, sources]);

  return sources ? currentSource : [currentSource, loadSources];
}
